<template>
  <div class="d-flex">
    <div class="pre-id" id="ghost-id-card">
      <div class="pre-id--wrap">
        <div class="pre-edge">
          <img src="/assets/images/corner.png" alt="" />
          <img class="flipped" src="/assets/images/corner.png" alt="" />
        </div>

        <img class="pre-id--logo" src="/assets/images/logoStill.png" alt="" />

        <div class="pre-id--photo filtered">
          <img
            class="pre-filter--corner pre-filter--corner-topleft"
            src="/assets/images/filter-topleft.png"
            alt=""
          />
          <img
            class="pre-filter--corner pre-filter--corner-topright"
            src="/assets/images/filter-topright.png"
            alt=""
          />
          <img
            class="pre-filter--corner pre-filter--corner-bottomleft"
            src="/assets/images/filter-bottomleft.png"
            alt=""
          />
          <img
            class="pre-filter--corner pre-filter--corner-bottomright"
            src="/assets/images/filter-bottomright.png"
            alt=""
          />
          <img
            id="id-image-tag"
            class="pre-id--photo-src"
            :src="currentProfile.photoUrl"
            :alt="currentProfile.nickName"
          />
        </div>

        <div class="pre-id--role">
          <img
            class="pre-id--role-img flipped"
            src="/assets/images/corner.png"
            alt=""
          />
          <div class="pre-id--role-name">{{ currentProfile.jobTitle }}</div>
        </div>

        <div class="pre-subhead glowing">
          {{ currentProfile.nickName }}
<!--          <router-link-->
<!--            v-if="currentProfile.isAdult"-->
<!--            class="pre-links&#45;&#45;item float-right"-->
<!--            :to="{ name: $routerItems.ONBOARDING_COMPLETE }"-->
<!--          >-->
<!--            <img-->
<!--                class="pre-subhead-action&#45;&#45;icon"-->
<!--                src="/assets/images/icons/pencil.svg"-->
<!--            />-->
<!--          </router-link>-->
        </div>

        <div class="pre-pad"></div>

        <div class="pre-divider"></div>

        <div class="pre-id--footer">
          <div class="pre-id--url">www.ghostbusters-paranormal.institute</div>
        </div>

        <div class="pre-edge">
          <img class="flipped" src="/assets/images/corner.png" alt="" />
          <img src="/assets/images/corner.png" alt="" />
        </div>
      </div>
    </div>
    <div class="description">
      <div class="page-title">
        Welcome to your Paranormal Institute student profile.
      </div>
      <div class="please-keep">
        Please keep your student ID close by during your studies.
      </div>

      <div class="pre-btns centered">
        <img
          class="pre-btn--lights"
          src="/assets/images/knightrider.png"
          alt=""
        />
        <pnw-button
          variant="primary"
          size="xl"
          @click="downloadIdCard"
          :disabled="downloading"
        >
          Download Id
        </pnw-button>
        <img
          class="pre-btn--lights flipped"
          src="/assets/images/knightrider.png"
          alt=""
        />
      </div>
      <div class="mt-5 pt-5">
        <div class="page-title">
          Gear up and get ready, we’re going ghost hunting.
        </div>
        <div class="please-keep">
          Be sure to visit the official Paranormal Institute shop to prepare for
          your studies.
        </div>
        <div class="pre-btns centered">
          <a
            href="https://shop.ghostbusters-paranormal.institute/"
            target="_blank"
          >
            <pnw-button variant="primary" size="xl"> Visit Shop </pnw-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toPng } from "html-to-image";
import { mapGetters } from "vuex";
import PnwButton from "../PnwButton.vue";

export default {
  components: { PnwButton },
  name: "ProfileIdSection",
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
    }),
  },
  data() {
    return {
      imageData: "",
      downloading: false,
    };
  },
  methods: {
    downloadIdCard() {
      this.downloading = true;
      toPng(document.getElementById("ghost-id-card"), { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = this.currentProfile.nickName + "-id.png";
          link.href = dataUrl;
          link.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1000px;
}
.pre-links--item {
  padding: 0;
}

.pre-subhead-action--icon {
  cursor: pointer;
  width: 27px;
  float:right;
}

.pre-id {
  max-width: 320px;
  .pre-subhead {
    font-size: 27px;
  }
}
.pre-id--photo {
  box-shadow: 0px 0px 38.7228px rgba(128, 255, 68, 0.5);
}
.description {
  margin-left: auto;
  max-width: 615px;
  padding: 0 0 60px 0;
  .page-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 90%;
    color: #6bca76;
    margin-bottom: 32px;
  }
  .please-keep {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;

    color: #6bca76;
    margin-bottom: 32px;
  }
}
</style>
