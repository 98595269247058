<template>
  <b-modal
    id="child-profile-confirm-modal"
    title="BootstrapVue"
    hide-header
    hide-footer
    centered
    size="md"
  >
    <div class="pre-edge">
      <img src="/assets/images/corner.png" alt="" />

      <pnw-button variant="primary" class="close-button" @click="close()">
        <img src="/assets/images/icons/close.svg" />
      </pnw-button>
    </div>

    <div class="content-body">
      <div class="title text-center">
        Are you creating a profile for a guest under 18
      </div>
      <div class="row">
        <div class="col-6 pl-4">
          <pnw-button
            size="xl"
            variant="primary"
            @click="closeAdultCheckModal(true)"
            class="w-100"
            >No</pnw-button
          >
        </div>
        <div class="col-6 pr-4">
          <pnw-button
            size="xl"
            variant="primary"
            @click="closeAdultCheckModal(false)"
            class="w-100"
            >Yes</pnw-button
          >
        </div>
      </div>
    </div>
    <div class="pre-edge">
      <img class="flipped" src="/assets/images/corner.png" alt="" />
      <img src="/assets/images/corner.png" alt="" />
    </div>
  </b-modal>
</template>
<script>
import PnwButton from "../PnwButton.vue";
export default {
  components: { PnwButton },
  name: "ChildProfileConfirmModal",
  methods: {
    closeAdultCheckModal(isAdult) {
      this.$bvModal.hide("child-profile-confirm-modal");
      this.$emit("confirmed", isAdult);
    },
    close() {
      this.$bvModal.hide("child-profile-confirm-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.close-button {
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.content-body {
  padding: 16px 48px;
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    margin-bottom: 32px;
  }
}
</style>
