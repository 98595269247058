<template>
  <div>
    <h2 class="mb-5 text-center">Your Paranormal Institute profiles</h2>
    <div class="profile-container" v-for="(item, idx) in profiles" :key="idx">
      <div
        class="pre-class--info"
        :class="{ other: item.id !== currentProfile.id }"
        @click="switchProfile(item)"
      >
        <div class="pre-class--user">
          <div class="pre-class--name">{{ item.nickName }}</div>
          <div class="pre-class--rolw" v-if="item.isAdult">
            {{ item.jobTitle }}
          </div>
        </div>
        <div class="pre-class--current" v-if="item.id === currentProfile.id">
          Current Profile
        </div>
      </div>
      <pnw-button
        variant="outline-primary"
        class="delete-btn"
        @click="removeProfile(item)"
        :disabled="item.id === currentProfile.id"
      >
        <img src="/assets/images/icons/trash.svg" alt="delete" />
      </pnw-button>
    </div>
    <div class="profile-actions">
      <div class="text-center mb-3">Enrolled in multiple classes?</div>
      <pnw-button
        variant="primary"
        size="xl"
        class="mb-4 w-100"
        @click="showConfirmModal"
        >Add new profile</pnw-button
      >
      <div class="text-center mb-3">Want to enroll in more classes?</div>
      <pnw-button
        variant="primary"
        size="xl"
        class="mb-3"
        v-if="isAdult"
        @click="getTickets"
        >Get Tickets</pnw-button
      >
      <pnw-button
        variant="outline-primary"
        class="mb-3"
        v-if="!isAdult"
        @click="getTickets"
        >Get Tickets</pnw-button
      >
    </div>
    <child-profile-confirm-modal @confirmed="addNewProfile" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import routerItems from "../../constants/routerItems";
import PnwButton from "../PnwButton.vue";
import ChildProfileConfirmModal from "./ChildProfileConfirmModal.vue";
export default {
  components: { PnwButton, ChildProfileConfirmModal },
  name: "ProfilesSection",
  props: {
    isAdult: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions({
      setProfile: "setProfile",
      removeProfileAction: "removeProfile",
      updateOnboardingData: "registerStore/updateOnboardingData",
    }),
    getTickets() {
      window.open(
        `https://tickets.secretcinema.org/ghostbusters-gates-of-gozer/`,
        "_blank"
      );
    },
    addNewProfile(isAdult) {
      this.updateOnboardingData({ isAdult });
      this.$router.push({
        name: routerItems.TICKET_CODE,
        query: { newProfile: true },
      });
    },
    showConfirmModal() {
      this.$bvModal.show("child-profile-confirm-modal");
    },
    switchProfile(profile) {
      if (profile.id === this.currentProfile.id) return;
      this.setProfile(profile.id);
    },
    removeProfile(profile) {
      if (profile.id === this.currentProfile.id) return;
      this.removeProfileAction(profile.id);
    },
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
      profiles: "userProfiles",
    }),
  },
};
</script>
<style lang="scss" scoped>
.profile-container {
  display: flex;
  margin-bottom: 16px;
  .profile {
    display: flex;
    height: 70px;
    border: solid 2px var(--primary);
    flex-grow: 1;
    margin-right: 8px;
    img {
      height: 100%;
    }
    .profile-contents {
      padding: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      .profile-type {
        font-weight: bold;
        font-size: 13px;
        line-height: 120%;
      }
    }
  }
  .delete-btn {
    width: 72px;
    height: 76px;
  }
}
.profile-actions {
  margin: 0 auto;
  margin-top: 32px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pre-class--info.other {
  cursor: pointer;
}
</style>
