<template>
  <div>
    <div v-if="isAuthenticated && !isStartSoonShow && !isPastShow">
      <div class="pre-copy centered">
        <p>Class starting in:</p>
      </div>
      <div class="pre-timer style colorDefinition size_sm">
        <div class="pre-timer--unit pre-timer--days">
          <div class="pre-timer--value">
            {{ remainDays }}
          </div>
          <div class="pre-timer--label">Days</div>
        </div>
        <div class="pre-timer--unit pre-timer--minutes">
          <div class="pre-timer--value">{{ remainHours }}</div>
          <div class="pre-timer--label">Hours</div>
        </div>
        <div class="pre-timer--unit pre-timer--minutes">
          <div class="pre-timer--value">{{ remainMinutes }}</div>
          <div class="pre-timer--label">Minutes</div>
        </div>
        <div class="pre-timer--unit pre-timer--seconds">
          <div class="pre-timer--value">{{ remainSeconds }}</div>
          <div class="pre-timer--label">Seconds</div>
        </div>
      </div>
      <div class="pre-classtime">
        <div class="pre-classtime--block">
          <img
            class="pre-classtime--icon"
            src="/assets/images/calendar.png"
            alt=""
          />
          <div class="pre-classtime--copy">{{ startDate }}</div>
        </div>
        <div class="pre-classtime--block">
          <img
            class="pre-classtime--icon"
            src="/assets/images/time.png"
            alt=""
          />
          <div class="pre-classtime--copy">{{ startTime }}</div>
        </div>
      </div>

      <div class="pre-divider my-5"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { startSoonLimit } from "../constants/showsConstants";
import dayHelpers from "../utils/dayHelper";

export default {
  name: "ClassStatus",
  data() {
    return {
      remainDays: 0,
      remainHours: 0,
      remainMinutes: 0,
      remainSeconds: 0,
      timer: null,
      isStartSoonShow: false,
      isPastShow: false,
    };
  },
  methods: {
    async loadInfo() {
      this.timer = setInterval(() => {
        if (this.ticketInfo?.event) {
          let diffTime = dayHelpers.getShowDuration(this.ticketInfo, "seconds");

          if (diffTime >= 0 && diffTime < startSoonLimit) {
            this.isStartSoonShow = true;
          } else {
            this.isStartSoonShow = false;
          }
          if (diffTime < 0) {
            this.remainDays = 0;
            this.remainHours = 0;
            this.remainMinutes = 0;
            this.remainSeconds = 0;
            this.isPastShow = true;
            return;
          }
          this.isPastShow = false;
          const diffSeconds = Math.round(diffTime % 60);
          diffTime = Math.round((diffTime - diffSeconds) / 60);
          const diffMinutes = diffTime % 60;
          diffTime = Math.round((diffTime - diffMinutes) / 60);
          const diffHours = diffTime % 24;
          const diffDays = Math.round((diffTime - diffHours) / 24);
          this.remainDays = diffDays;
          this.remainHours = diffHours;
          this.remainMinutes = diffMinutes;
          this.remainSeconds = diffSeconds;
        } else {
          this.remainDays = 0;
          this.remainHours = 0;
          this.remainMinutes = 0;
          this.remainSeconds = 0;
        }
      }, 1000);
    },
  },
  mounted() {
    this.loadInfo();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      currentProfile: "currentProfile",
      ticketInfo: "ticketInfo",
      isStartSoon: "isStartSoon",
    }),
    startDate() {
      return dayHelpers.getShowDate(this.ticketInfo);
    },
    startTime() {
      return dayHelpers.getShowTime(this.ticketInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.pre-timer {
  max-width: 500px;
}
</style>
