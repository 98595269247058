<template>
  <div class="letter-section">
    <h2>Dear students,</h2>
    <p>
      My name is Janine Melnitz, and I'll be guiding you throughout your
      training.
    </p>
    <p>
      Now that you're enrolled, you'll be joining the many students who will be
      connecting to the Paranormal Institute via S.P.I.R.I.T. Net (Spengler's
      Intercontinental Relayable Interactive Telecommunications Network).
    </p>
    <p>
      You'll meet other students where you'll be trained in the highly
      specialised art of Ghostbusting to get you up to speed and field ready.
    </p>
    <p>
      You'll have the chance to blast, battle, doge and debate your way to the
      top of the class and ensure your team are the number one Ghostbuster
      graduates.
    </p>
    <p>
      We'll head downtown to 550 Central Park West, where not only will you have
      a guided tour of the infamous Shandor Building and its various residents,
      you will also hear from prominent Gozerian academic, Dr Rudolph Whitehead.
    </p>
    <p>See you in roll call.</p>
  </div>
</template>
<script>
export default {
  name: "LetterSection",
  methods: {},
};
</script>
<style lang="scss" scoped>
.letter-section {
  max-width: 545px;
  margin: 0 auto;
  text-align: left;
}
</style>
