<template>
  <div class="container profile-container">
    <div>
      <class-status />
    </div>
    <id-section />
    <div class="pre-divider my-5"></div>
    <letter-section />
    <div class="pre-divider my-5" v-if="currentProfile.isAdult"></div>
    <profiles-section :isAdult="currentProfile.isAdult" />
    <div class="pre-divider my-5"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ClassStatus from "../components/ClassStatus.vue";
import IdSection from "../components/Profile/IdSection.vue";
import ProfilesSection from "../components/Profile/ProfilesSection.vue";
import LetterSection from "../components/Profile/LetterSection.vue";
import voltApiService from "../services/VoltApiService";

export default {
  components: {
    ClassStatus,
    IdSection,
    ProfilesSection,
    LetterSection,
  },
  name: "YourProfile",
  data() {
    return {
      isAdult: true,
    };
  },
  mounted() {
    this.getTicketInfo();
  },
  methods: {
    ...mapActions({
      updateTicketInfo: "updateTicketInfo",
    }),
    async getTicketInfo() {
      const ticketInfo = await voltApiService.getTicketInfo(
        this.currentProfile?.ticketCode
      );
      this.updateTicketInfo(ticketInfo);
    },
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/colors";
.profile-container {
  max-width: 1000px;
}
.card-img-container {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px $primary;
  padding: 8px;
  .camera-box {
    position: relative;
    .camera-shutter {
      box-sizing: border-box;
      position: absolute;
      z-index: 10;
      border: dashed 1px $green;
      width: 212px;
      height: 212px;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 2px), calc(-50% - 2px));
    }
  }
  img {
    max-width: 100%;
  }
  .uploaded-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.card-taken-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
